import React from "react";
import PropTypes from "prop-types";

import {
  Icon,
  Label,
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxContainer,
  Description,
  Content,
  Title
} from "./checkbox/CheckboxStyle";

export const Checkbox = ({ checked, children, description, className, ...props }) => (
  <CheckboxContainer className="checkbox">
    <Label checked={checked} className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <Content>
        <StyledCheckbox className="StyledCheckbox" checked={checked}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
        <Title>{children}</Title>
      </Content>
      {description && <Description>{description}</Description>}
    </Label>
  </CheckboxContainer>
);

Checkbox.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  description: PropTypes.string
};

Checkbox.defaultProps = {
  description: "",
  checked: false
};
